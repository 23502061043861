<template>
    <div class="col-lg-12">
        <form class="form-horizontal" enctype="multipart/form-data" @submit.prevent="onSubmit" @keydown="form.errors.clear($event.target.name)">
            <div class="form-group" v-bind:class="{ 'has-error' : form.errors.has('email') }">
                <label for="email">{{ $t('email') }}</label>
                <input type="text" class="form-control" name="email" :placeholder="$t('email_placeholder')" v-model="form.email" required>
                <span class="help-block" v-if="form.errors.has('email')" v-text="form.errors.get('email')"></span>
            </div>
            <div class="form-group" v-bind:class="{ 'has-error' : form.errors.has('password') }">
                <label for="password">{{ $t('password') }}</label>
                <input type="password" class="form-control" name="password" v-model="form.password" required>
                <span class="help-block" v-if="form.errors.has('password')" v-text="form.errors.get('password')"></span>
            </div>
            <div class="form-group">
                <input :disabled="form.busy" type="submit" :value="$t('sign_in')" class="btn btn-primary btn-block" />
            </div>
            <router-link :to="{ name: 'password.reset'}" class="forgot">{{ $t('forgot_password') }}</router-link>
        </form>
    </div>
</template>
<script>
import Form from '~/Form/Form'
import store from '~/store'

export default {
    name: "login",
    data() {
        return {
            form: new Form({
                email: '',
                password: '',
            })
        }
    },
    methods: {
        async onSubmit() {
            const data = await this.form.post('/api/login').catch(response => this.form.password = '')

            // Save the token.
            this.$store.dispatch('saveToken', {
                token: data.token,
                remember: this.remember
            })

            // Fetch the user.
            const user = await this.$store.dispatch('fetchUser')

            // Redirect home.
            this.$router.push({ name: store.getters.authAdmin ? 'admin.dashboard' : 'dashboard' })
        }
    },
    mounted: function() {

    },
    destroyed: function() {

    },

}
</script>
<style lang="scss" scoped>
   
</style>
