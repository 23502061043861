import Errors from './Errors';

class Form {
    /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
    constructor(data) {
        this.originalData = data;
        this.busy = false;
        for (let field in data) {
            this[field] = data[field];
        }

        this.errors = new Errors();
    }


    /**
     * Fetch all relevant data for the form.
     */
    data() {
        let data = {};

        for (let property in this.originalData) {
            data[property] = this[property];
        }

        return data;
    }

    fill(data) {
        for (let property in data) {
            this.setId(data.id);
            if (property in this) {
                this[property] = data[property];
            }
        }
    }

    setId(id) {
        this.id = id;
        this.originalData['id'] = id;
    } 
    /**
     * Reset the form fields.
     */
    reset() {
        for (let field in this.originalData) {
            this[field] = '';
        }

        this.errors.clear();
    }


    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     */
    post(url) {
        return this.submit('post', url);
    }


    /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     */
    put(url) {
        return this.submit('put', url);
    }


    /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     */
    patch(url) {
        return this.submit('patch', url);
    }


    /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     */
    delete(url) {
        return this.submit('delete', url);
    }


    /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     */
    submit(requestType, url) {
        this.busy = true;
        return new Promise((resolve, reject) => {
            axios[requestType](url, this.data())
            .then(response => {
                this.busy = false;
                resolve(response.data);
            })
            .catch(error => {
                this.busy = false;
                if (error.response.status == 400) {
                    return this.displayErrors(error.response.data)
                }
                this.onFail(error.response.data.errors);
                reject(error.response.data);
            });
        });
    }

    displayErrors(response) {
        swal("Oops", response.message, "error");
    }

    hasDismissButton(response) {
        return 'dismissButton' in response;
    }

    hasRedirectButton(response) {
        return 'redirectButton' in response;
    }

    /**
     * Handle a failed form submission.
     *
     * @param {object} errors
     */
    onFail(errors) {
        this.errors.record(errors);
    }
}

export default Form
